<template>
  <b-modal
            id="modal-center-sync"
            ref="modal-center-sync"
            centered
            ok-title="Continue to Dialog"
            class="justify-content-center"
            @ok="onCloseModal"
            ok-only
            v-on:close="onCloseModal"
            no-close-on-backdrop
            >
          <div class="modal-text">
            <span class="modal-title">{{ $t("Welcome to Dialog!") }}</span><br/>
            <span class="modal-description">
              {{ $t("You have successfully synchronized") }} {{  }}<br/><br/>
              {{ $t("We offer you") }} <b>{{$t("6 months FREE")}}</b> {{$t("access so that you fully explore and enjoy all the features.")}}<br/><br/>
              {{ $t("Start now your journey through a connected kitchen.") }}
            </span>
          </div>
    </b-modal>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  VBTooltip,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";


export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VBTooltip,
    VueApexCharts,
    ToastificationContent
  },
  data() {
    return {
      data: {
        deviceId:null,
        showModal:false,
        syncPageId:null,
      },
      
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  computed:{
    currentUserShowModals(){
      return this.$store.state.users.currentUserShowModals 
    },
    currentUser(){
      return this.$store.state.users.currentUser;
    },
  },
  methods:
  {
    onCloseModal(){
      let data = {sync_page:true}   
      this.$store.dispatch("users/setPageView", { context: this, data, id:this.syncPageId })
      this.$router.push({name:'device', params: { id: this.deviceId}})
    },
    getData(){
      let devicesEndpoint = `${process.env.VUE_APP_API_URL}api/devices/`
      let data = {token:this.$route.query.token}
      this.$http.post(`${devicesEndpoint}sync-device/`, data)
      .then(resp => 
      {
        if(resp.status === 200)
        {
          this.deviceId = resp.data.device_id
          this.showModal = resp.data.user_pages.sync_page
          this.syncPageId = resp.data.user_pages.id
          this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sync Device',
                    icon: 'RefreshCwIcon',
                    variant: 'success',
                    text: resp.data.message
                  },
                })
        }
          })
          .then(()=>
          {
              if(this.showModal == false)
                this.$refs['modal-center-sync'].show()
              else{
                return this.$router.push({name:'device', params: { id: this.deviceId}})
              }
          })
      .catch(error => 
      {
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sync Failed',
                icon: 'AlertTriangleIcon',    
                text: error,
                variant: 'danger'
                },
            })
        this.$router.push({name:'home'})

      })
    }
  },
  created() {
    this.getData()
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-alerts.scss";
</style>
